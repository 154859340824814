import axios from 'axios';
import { BACKEND_URL } from '../utils/url';
export const insertSearchFilterTemplate = async (dataObj) => {
  try {
    const saveToDb = await axios.post(
      `${BACKEND_URL}/search_templates/insert`,
      {
        name: dataObj.name,
        file_name: dataObj.file_name,
        userId: dataObj.userId,
        description: dataObj.description,
        purpose: dataObj.purpose,
        patientCount: dataObj.patientCount,
        filter: dataObj.filter,
      },
    );

    // await axios.post(`${BACKEND_URL}/data_loader/datatable`, {
    //   fileDbId: saveToDb.data,
    //   userId: dataObj.userId,
    //   graphql_query: dataObj.graphql_query,
    //   file_name: dataObj.file_name,
    // });
    return saveToDb.data;
  } catch (error) {
    throw error;
  }
};

export const checkTemplateName = async (dataObj) => {
  try {
    const { data } = await axios.post(
      `${BACKEND_URL}/search_templates/check`,
      dataObj,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
export const getAllUserTemplates = async (dataObj) => {
  try {
    const { data } = await axios.post(
      `${BACKEND_URL}/search_templates/getall`,
      dataObj,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const removeTemplate = async (id, userId) => {
  try {
    const { data } = await axios.delete(
      `${BACKEND_URL}/search_templates/delete/${id}/${userId}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateTemplate = async (dataObj) => {
  try {
    const id = dataObj.id;
    delete dataObj.id;
    delete dataObj.creationDate;
    delete dataObj.lastDownloadingDate;
    delete dataObj.updateingDate;
    const { data } = await axios.put(
      `${BACKEND_URL}/search_templates/update/${id}`,
      {
        name: dataObj.name,
        file_name: dataObj.file_name,
        userId: dataObj.userId,
        description: dataObj.description,
        purpose: dataObj.purpose,
        patientCount: dataObj.patientCount,
        filter: dataObj.filter,
      },
    );
    await axios.post(`${BACKEND_URL}/data_loader/datatable`, {
      fileDbId: id,
      userId: dataObj.userId,
      graphql_query: dataObj.graphql_query,
      file_name: dataObj.file_name,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
