import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React, { useEffect, useState } from 'react';
import { FilterFields } from './FilterFields';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@mui/lab';

import { AISphere } from './AISphere';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useApi } from '../../hooks/useApi';
import { AIApi } from '../../api/AIApi';
import validator from 'validator';
import { Instruction } from '../Instruction/Instruction';

export const BigFilter = ({
  open,
  closeAction,
  styles,
  buttons,
  otherFiltersFields,
  filterData,
  currentFilterData,
  setCurrentFilterData,

  renderedFilter,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchInFilterString, setSearchInFilterString] = useState('');
  const [searchInFilterResult, setSearchInFilterResult] = useState([]);
  const [selectedSearchItem, setSelectedSearchItem] = useState('');
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [openAI, setOpenAI] = useState(false);
  const [AIActive, setAIActive] = useState(false);
  const [context, setContext] = useState('');
  const AIApiApi = useApi(AIApi);
  const [AIHasResult, setAIHasResult] = useState(false);
  const [selectedFiltersParams, setSelectedFiltersParams] = useState([]);

  const AIApiHandler = async () => {
    const { filters } = await AIApiApi.sendRequest(context);
    if (filters['Нет данных'] === '' || Object.keys(filters)?.length == 0) {
      setTimeout(() => {
        setAIHasResult({ result: true, error: true });
      }, 3500);
    } else {
      const resultArray = [];

      for (const i in filters) {
        let graphQlQueryString;
        let value;
        if (
          typeof filters[i] == 'object' &&
          filters[i]?.length == 2 &&
          validator.isDate(filters[i][0]) &&
          validator.isDate(filters[i][1])
        ) {
          value = {
            startDate: new Date(filters[i][0]),
            endDate: new Date(filters[i][1]),
          };
          graphQlQueryString = {
            between: [new Date(filters[i][0]), new Date(filters[i][1])],
          };
        }
        if (typeof filters[i] == 'string' && validator.isDate(filters[i])) {
          value = {
            startDate: new Date(filters[i]),
            endDate: new Date(filters[i]),
          };
          graphQlQueryString = {
            between: [new Date(filters[i]), new Date(filters[i])],
          };
        } else if (typeof filters[i] == 'object' && filters[i]?.length > 0) {
          value = filters[i];
          graphQlQueryString = {
            in_: value,
          };
        } else {
          value = filters[i];
          graphQlQueryString = { eq: value };
        }

        resultArray.push({
          anyValue: false,
          graphQlQueryString,
          graphQlTable: undefined,
          key: i,
          value,
          name: '',
          fromAI: true,
        });
      }
      setCurrentFilterData(resultArray);
      setTimeout(() => {
        setAIHasResult({ result: true, error: false });
        setSelectedFiltersParams([
          {
            key: 'selectedParams',
            name: 'Фильтры от ИИ',
            params: []
              .concat(...filterData?.map((item) => item?.params))
              ?.filter((item) => !!filters[item?.key]),
          },
        ]);
      }, 3500);
    }
  };
  const filterParams = (f = filterData) => {
    const parent = f?.find((item, index) => index == selectedTab);

    return parent.params.map((param, index) => (
      <Grid item xs={3} key={param?.key + index + 1}>
        <div style={{ position: 'relative' }}>
          <FilterFields
            key={param?.key}
            value={currentFilterData?.find((i) => i.key == param.key)?.value}
            anyValue={
              currentFilterData?.find((i) => i.key == param.key)?.anyValue
            }
            param={param}
            focusedSearchKey={selectedSearchItem}
            parentKey={parent?.key}
            currentFilterData={currentFilterData}
            changeCurrentFilterData={(data) => setCurrentFilterData(data)}
            disableMe={
              param.key?.indexOf('<название анализа>') != -1 &&
              !currentFilterData.some(
                (cd) => cd?.key == 'MedicalDocuments_DocTitle',
              )
            }
          />
        </div>
      </Grid>
    ));
  };
  return (
    <>
      <Box className={styles.BigFilter__Box}>
        {renderedFilter ? (
          <div className={styles.BigFilter__Box__Content}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="h5" as="p">
                Расширенный фильтр
              </Typography>
              <IconButton aria-label="close" onClick={closeAction}>
                <ClearOutlinedIcon />
              </IconButton>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <Stack sx={{ width: '100%', position: 'relative' }}>
                <TextField
                  id="input-with-icon-adornment"
                  label="Поиск по фильтру"
                  size="medium"
                  // fullWidth

                  value={searchInFilterString}
                  onChange={({ target: { value } }) => {
                    setSearchInFilterString(value);
                    setShowSearchResult(true);
                    const paramsArrayInArray = filterData.map((f, i) =>
                      f.params?.map((p) => ({
                        ...p,
                        parentIndex: i,
                        parentName: f.name,
                      })),
                    );
                    const params = [].concat(...paramsArrayInArray);

                    setSearchInFilterResult(
                      params?.filter(
                        (p) =>
                          p?.name
                            ?.toLowerCase()
                            ?.indexOf(value?.toLowerCase()) != -1,
                      ),
                    );
                  }}
                />
                {searchInFilterString && showSearchResult && (
                  <div className={styles.BigFilter__SearchInFilterResult}>
                    {searchInFilterResult?.map((item) => (
                      <p
                        onClick={() => {
                          setShowSearchResult(false);
                          setSelectedSearchItem(item?.key);
                          setSelectedTab(item?.parentIndex);
                        }}
                      >{`${item?.parentName} - ${item?.name}`}</p>
                    ))}
                  </div>
                )}
              </Stack>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%', mb: 3 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <LoadingButton
                  color="success"
                  fullWidth
                  key={uuidv4()}
                  sx={{ flexShrink: 1, textTransform: 'none' }}
                  variant="contained"
                  onClick={() => {
                    setOpenAI(true);
                  }}
                  startIcon={<AutoAwesomeOutlinedIcon />}
                >
                  AI помощник
                </LoadingButton>
              </Box>
              <Stack direction="row" spacing={2}>
                {buttons({ fullWidth: false, size: 'large' }).reverse()}
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              {otherFiltersFields().map((item) => item)}
            </Stack>

            <Tabs
              onChange={(event, value) => setSelectedTab(value)}
              aria-label="lab API tabs example"
              value={selectedTab}
            >
              {[...selectedFiltersParams, ...filterData]?.map((cat, index) => (
                <Tab
                  key={cat?.name + index + 1}
                  label={cat?.name}
                  value={index}
                  id={`simple-tab-${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                />
              ))}
            </Tabs>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              {selectedFiltersParams &&
                filterParams([...selectedFiltersParams, ...filterData])}
            </Grid>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Box>
      <Modal
        open={openAI}
        onClose={() => setOpenAI(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '600px',
            height: '90vh',
            background: '#fff',
            borderRadius: '12px',
            p: 4,
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2}>
              <Box>
                <AutoAwesomeOutlinedIcon />
              </Box>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  AI Помощник
                </Typography>
              </Box>
            </Stack>
            <Box>
              <IconButton onClick={() => setOpenAI(false)}>
                <ClearOutlinedIcon />
              </IconButton>
            </Box>
          </Stack>
          <Typography id="modal-modal-description">
            Напишите вашу задачу, а нейронная сеть подберет подходящие параметры
            для фильтра
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <AISphere AIActive={AIActive} hasResult={AIHasResult} />

          {!AIActive && (
            <FormControl sx={{ width: '100%' }} variant="outlined">
              {/* <InputLabel htmlFor="outlined-adornment-password">
                Опишите вашу задачу
              </InputLabel> */}
              <OutlinedInput
                fullwidth
                id="outlined-adornment-password"
                placeholder="Опишите ваш вопрос в свободной форме"
                sx={{ pr: 3 }}
                value={context}
                onChange={({ target: { value } }) => setContext(value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      // aria-label={
                      //   showPassword ? 'hide the password' : 'display the password'
                      // }
                      onClick={() => {
                        setAIActive(true);
                        AIApiHandler();
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      // onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
          {AIHasResult && (
            <Stack direction="row" spacing={2} justifyContent="center">
              {!AIHasResult?.error && (
                <Box>
                  <Button
                    sx={{ textTransform: 'none' }}
                    color="success"
                    variant="contained"
                    onClick={() => {
                      setSelectedTab(0);
                      setOpenAI(false);
                    }}
                  >
                    Посмотреть фильтр
                  </Button>
                </Box>
              )}
              <Box>
                <Button
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    setCurrentFilterData([]);
                    setAIActive(false);
                    setAIHasResult(false);
                    setSelectedFiltersParams([]);
                  }}
                >
                  Сбросить фильтр
                </Button>
              </Box>
            </Stack>
          )}
        </Box>
      </Modal>
    </>
  );
};
