import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import styles from './ActionApprove.module.scss';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';
export const ActionApprove = ({
  closeAction,
  successAction,
  open,
  closeActionButtonText,
  successActionButtonText,
  successActionButtonColor = 'error',
  title,
  children,
  successActionButtonVariant = 'outlined',
  modalVariant = 'standart',
  showCloseButton = true,
  disabledButton = false,
  alertMessages = {
    success: '',
    error: '',
  },
}) => {
  const dispatch = useDispatch();
  return (
    <Modal
      open={open}
      onClose={closeAction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.ActionApprove}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box className={styles.ActionApprove__Box}>
        <Stack
          className={styles.ActionApprove__Box__HeaderTitle}
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={
            modalVariant == 'primary'
              ? { background: '#303E9F !important', color: '#fff !important' }
              : {}
          }
        >
          <Typography as="p">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={closeAction}
            sx={modalVariant == 'primary' ? { color: '#fff !important' } : {}}
          >
            <ClearOutlinedIcon />
          </IconButton>
        </Stack>
        <div className={styles.ActionApprove__Box__Content}>
          {children}

          <Stack
            direction="row"
            spacing={2}
            justifyContent="end"
            sx={{ mt: 3 }}
          >
            {showCloseButton && (
              <Button
                variant="outlined"
                color="inherit"
                sx={{ opacity: 0.4, textTransform: 'none' }}
                onClick={closeAction}
              >
                {closeActionButtonText}
              </Button>
            )}

            <Button
              variant={successActionButtonVariant}
              color={successActionButtonColor}
              disabled={disabledButton}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                successAction();
                dispatch(
                  setAlert({ text: alertMessages?.success, status: 200 }),
                );
              }}
            >
              {successActionButtonText}
            </Button>
          </Stack>
        </div>
      </Box>
    </Modal>
  );
};
