import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { DatePicker, DateRangePicker } from 'rsuite';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { gql, useLazyQuery } from '@apollo/client';
import { Instruction } from '../Instruction/Instruction';

export const FilterFieldV2 = ({
  param,
  ChangeFilterDataHandler,
  selectFilterData,
  setSelectedFilterData,
  selectedSearchItem,
}) => {
  const [multiselect, setMultiselect] = useState([]);
  const MULTI_SELECT_DEFAULT_DATA_QUERY = gql`
    query MyQuery {
      __typename ## Placeholder value
    }
  `;
  let [gqlSearch, { error, data, loading }] = useLazyQuery(
    MULTI_SELECT_DEFAULT_DATA_QUERY,
  );
  const getMultiSelectDefaultData = async (key) => {
    try {
      const table = key.split('_')[0];
      const field = key.split('_')[1];
      const { data } = await gqlSearch({
        fetchPolicy: 'no-cache',
        query: gql`
            query {
              ${table}{
                ${field}
              }
            }
            `,
      });

      return data[table][field];
    } catch (error) { }
  };

  useEffect(() => {
    if (param?.defaultResolverName) {
      getMultiSelectDefaultData(param?.defaultResolverName).then((result) =>
        setMultiselect(result),
      );
    } else if (param?.defaultData?.length) {

      setMultiselect(param?.defaultData);
    }
  }, []);



  return (
    <Tooltip title={param?.information}>
      <Stack sx={{ mb: 2 }}>

        {param?.fieldType == 'TextField' && (

          <TextField
            key={param.key}
            variant="outlined"
            // disabled={apiLoading || disableMe}
            focused={param.key == selectedSearchItem}
            value={
              selectFilterData?.find((item) => item.key == param.key)?.value ||
              ''
            }
            label={param?.name}
            fullWidth
            onChange={({ target: { value } }) => {
              ChangeFilterDataHandler({
                anyValue: false,
                graphQlQueryString: { contains: value },
                key: param?.key,
                name: param?.name,
                fieldType: param.fieldType,
                value,
              });
            }}
          />
        )}
        {param?.fieldType == 'NumberField' && (
          <TextField
            key={param.key}
            variant="outlined"
            // disabled={apiLoading || disableMe}
            focused={param.key == selectedSearchItem}
            value={
              selectFilterData?.find((item) => item.key == param.key)?.value ||
              ''
            }
            label={param?.name}
            fullWidth
            onChange={({ target: { value } }) => {
              ChangeFilterDataHandler({
                anyValue: false,
                graphQlQueryString: { eq: +value },
                key: param?.key,
                name: param?.name,
                fieldType: param.fieldType,
                value,
              });
            }}
          />
        )}
        {param?.fieldType == 'NumberRange' && (
          <FormControl fullWidth key={param?.key}>
            <Stack
              key={param.key}
              labelId={`multiple-name-label-${param?.key}`}
              direction="row"
              spacing={1}
            >
              <TextField
                // key={'from' + key}
                // disabled={apiLoading || disableMe}
                variant="outlined"
                label={param?.name + ' (От)'}
                fullWidth
                focused={param.key == selectedSearchItem}
                value={
                  selectFilterData?.find((item) => item.key == param.key)?.value
                    ?.from || ''
                }
                onChange={({ target }) => {
                  ChangeFilterDataHandler({
                    anyValue: false,
                    graphQlQueryString: {
                      between: [
                        +target.value || null,
                        +selectFilterData?.find((item) => item.key == param.key)
                          ?.value?.to || null,
                      ],
                    },
                    key: param?.key,
                    name: param?.name,
                    value: {
                      to: +selectFilterData?.find(
                        (item) => item.key == param.key,
                      )?.value?.to,
                      from: +target.value,
                    },
                    fieldType: param.fieldType,
                  });
                }}
              />
              <TextField
                key={'to' + param.key}
                // disabled={apiLoading || disableMe}

                variant="outlined"
                focused={param.key == selectedSearchItem}
                label={param?.name + ' (До)'}
                fullWidth
                value={
                  selectFilterData?.find((item) => item.key == param.key)?.value
                    ?.to || ''
                }
                onChange={({ target }) => {
                  ChangeFilterDataHandler({
                    anyValue: false,
                    graphQlQueryString: {
                      between: [
                        +selectFilterData?.find((item) => item.key == param.key)
                          ?.value?.from || null,
                        +target.value || null,
                      ],
                    },
                    key: param?.key,
                    name: param?.name,
                    fieldType: param.fieldType,
                    value: {
                      from: +selectFilterData?.find(
                        (item) => item.key == param.key,
                      )?.value?.from,
                      to: +target.value,
                    },
                  });
                }}
              />
            </Stack>
          </FormControl>
        )}
        {param?.fieldType == 'DateField' && (
          <LocalizationProvider dateAdapter={AdapterDateFns} key={param?.key}>
            <DatePicker
              label={param?.name}
              // disabled={apiLoading || disableMe}
              focused={param.key == selectedSearchItem}
              value={
                selectFilterData?.find((item) => item.key == param.key)
                  ?.value ||
                '' ||
                null
              }
              sx={{ width: '100%' }}
              onChange={(value) => {
                ChangeFilterDataHandler({
                  anyValue: false,
                  graphQlQueryString: {
                    eq: format(value, 'yyyy-MM-dd'),
                  },
                  key: param?.key,
                  name: param?.name,
                  value,
                  fieldType: param.fieldType,
                });
              }}
            />
          </LocalizationProvider>
        )}
        {param?.fieldType == 'DateRange' && (
          <Box sx={{ position: 'relative', width: '100%' }}>
            {selectFilterData?.find((item) => item.key == param.key)?.value
              ?.endDate && (
                <InputLabel
                  htmlFor={`DateRange-${param?.key}`}
                  sx={{
                    position: 'absolute',
                    background: '#fff',
                    top: '-9px',
                    left: 10,
                    zIndex: 9,
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    lineHeight: '1.4375em',
                    letterSpacing: '0.00938em',
                  }}
                >
                  {param?.name}
                </InputLabel>
              )}
            <DateRangePicker
              id={`DateRange-${param?.key}`}
              locale={{
                sunday: 'вс',
                monday: 'пн',
                tuesday: 'вт',
                wednesday: 'ср',
                thursday: 'чт',
                friday: 'пт',
                saturday: 'сб',
                ok: 'Ок',
                today: 'Сегодня',
                yesterday: 'Вчера',
                hours: 'Час.',
                minutes: 'Мин.',
                seconds: 'Сек.',
                last7Days: 'Последние 7 дней',
              }}
              style={{ top: '-100px', width: '100%' }}
              size="lg"
              placeholder={param?.name}
              // disabled={apiLoading || disableMe}
              key={param?.key}
              value={[
                typeof selectFilterData?.find((item) => item.key == param.key)
                  ?.value?.startDate == 'string'
                  ? new Date(
                    parseISO(
                      selectFilterData?.find((item) => item.key == param.key)
                        ?.value?.startDate,
                    ),
                  )
                  : selectFilterData?.find((item) => item.key == param.key)
                    ?.value?.startDate || null,
                typeof selectFilterData?.find((item) => item.key == param.key)
                  ?.value?.endDate == 'string'
                  ? new Date(
                    parseISO(
                      selectFilterData?.find((item) => item.key == param.key)
                        ?.value?.endDate,
                    ),
                  )
                  : selectFilterData?.find((item) => item.key == param.key)
                    ?.value?.endDate || null,
              ]}
              onChange={(dates) => {
                ChangeFilterDataHandler({
                  anyValue: false,
                  graphQlQueryString: {
                    between: [
                      dates?.length && dates[0]
                        ? format(new Date(dates[0]), 'yyyy-MM-dd')
                        : null,
                      dates?.length && dates[1]
                        ? format(new Date(dates[1]), 'yyyy-MM-dd')
                        : null,
                    ],
                  },
                  key: param?.key,
                  name: param?.name,
                  value: {
                    startDate: dates?.length && dates[0] ? dates[0] : null,
                    endDate: dates?.length && dates[1] ? dates[1] : null,
                  },
                  fieldType: param.fieldType,
                });
              }}
            />
          </Box>
        )}
        {param?.fieldType == 'MultiSelect' && (

          <FormControl
            key={param?.key}
            fullWidth
            focused={param.key == selectedSearchItem}
          >
            <InputLabel id={`multiple-name-label-${param?.key}`}>
              {param?.name}
            </InputLabel>
            <Select
              name={param.key}
              focused={param.key == selectedSearchItem}
              // disabled={apiLoading || disableMe}
              defaultValue=""
              labelId={`multiple-name-label-${param?.key}`}
              id={`multiple-name-${param?.key}`}
              multiple
              // onOpen={() => setOpenSelect(true)}
              // onClose={() => setOpenSelect(false)}
              // open={openSelect}
              value={
                selectFilterData?.find((item) => item.key == param.key)
                  ?.value || []
              }
              onChange={({ target: { value } }) => {
                ChangeFilterDataHandler({
                  anyValue: false,
                  graphQlQueryString: {
                    in_: value,
                  },
                  key: param?.key,
                  name: param?.name,
                  value,
                  fieldType: param.fieldType,
                  defaultData: param?.defaultData,
                });
              }}
              input={<OutlinedInput label={param?.name} />}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                  }}
                >
                  {selected?.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            // MenuProps={MenuProps}
            >
              {multiselect?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
              {/* {!loading ? (
                multiSelectDefaultArray?.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))
              ) : (
                <CircularProgress />
              )} */}
            </Select>
          </FormControl>

        )}
        {param?.fieldType == 'SingleSelect' &&
          (param?.key == 'MedicalDocuments_DocTitle' ? (
            <FormControl
              key={param?.key}
              fullWidth
              focused={param.key == selectedSearchItem}
            >

              <InputLabel id={`simple-name-label-${param?.key}`}>
                {param?.name}
              </InputLabel>

              <Select
                name={param.key}
                focused={param.key == selectedSearchItem}
                // onOpen={() => setOpenSelect(true)}
                // onClose={() => setOpenSelect(false)}
                // open={openSelect}
                defaultValue=""
                labelId={`simple-name-label-${param?.key}`}
                id={`simple-name-${param?.key}`}
                // disabled={apiLoading || disableMe}
                value={
                  selectFilterData?.find((item) => item.key == param.key)
                    ?.value || ''
                }
                label={param?.name}
                onChange={({ target: { value } }) => {
                  ChangeFilterDataHandler({
                    anyValue: false,
                    graphQlQueryString: {
                      eq: value,
                    },
                    key: param?.key,
                    name: param?.name,
                    value,
                    fieldType: param.fieldType,
                    defaultData: param?.defaultData,
                  });
                }}
              >
                {param?.defaultData?.map((item, index) => (
                  <MenuItem key={index} value={item[0]}>
                    {item[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl
              key={param?.key}
              fullWidth
              focused={param.key == selectedSearchItem}
            >

              <InputLabel id={`simple-name-label-${param?.key}`}>
                {param?.name}
              </InputLabel>
              <Select
                name={param.key}
                focused={param.key == selectedSearchItem}
                // onOpen={() => setOpenSelect(true)}
                // onClose={() => setOpenSelect(false)}
                // open={openSelect}
                defaultValue=""
                labelId={`simple-name-label-${param?.key}`}
                id={`simple-name-${param?.key}`}
                // disabled={apiLoading}
                value={
                  selectFilterData?.find((item) => item.key == param.key)
                    ?.value || ''
                }
                label={param?.name}
                onChange={({ target: { value } }) => {
                  ChangeFilterDataHandler({
                    anyValue: false,
                    graphQlQueryString: {
                      eq: value,
                    },
                    key: param?.key,
                    name: param?.name,
                    value,
                    fieldType: param.fieldType,
                    defaultData: param?.defaultData,
                  });
                }}
              >
                {(multiselect?.length ? multiselect : param?.defaultData)?.map(
                  (item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          ))}
        {param?.fieldType == 'BooleanSelect' && (
          <FormControl
            key={param?.key}
            fullWidth
            focused={param.key == selectedSearchItem}
          >

            <InputLabel id={`simple-name-label-${param?.key}`}>
              {param?.name}
            </InputLabel>
            <Select
              name={param.key}
              defaultValue=""
              // onOpen={() => setOpenSelect(true)}
              // onClose={() => setOpenSelect(false)}
              // open={openSelect}
              focused={param.key == selectedSearchItem}
              labelId={`simple-name-label-${param?.key}`}
              id={`simple-name-${param?.key}`}
              // disabled={apiLoading || disableMe}
              value={
                selectFilterData?.find((item) => item.key == param.key)
                  ?.value || ''
              }
              label={param?.name}
              onChange={({ target: { value } }) =>
                ChangeFilterDataHandler({
                  anyValue: false,
                  graphQlQueryString: {
                    eq: value,
                  },
                  key: param?.key,
                  name: param?.name,
                  value,
                  fieldType: param.fieldType,
                  defaultData: param?.defaultData,
                })
              }
            >
              {param?.defaultData?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item ? 'Да' : 'Нет'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControlLabel
            sx={{ opacity: '0.7' }}
            control={
              <Checkbox
                size="small"
                checked={
                  selectFilterData?.find((item) => item.key == param.key)
                    ?.anyValue
                }
                // disabled={apiLoading || disableMe}
                onChange={({ target: { checked } }) => {
                  if (!checked) {
                    setSelectedFilterData(
                      selectFilterData?.filter((p) => p.key !== param.key),
                    );
                  } else {
                    ChangeFilterDataHandler({
                      anyValue: checked,
                      graphQlQueryString: {},
                      key: param?.key,
                      name: param?.name,
                      value: null,
                      fieldType: param.fieldType,
                    });
                  }
                }}
              />
            }
            label="Любое значение"
          />
          {param?.fromAI && (
            <Stack direction="row" spacing={0.3} alignItems="center">
              <AutoAwesomeOutlinedIcon fontSize="10px" />
              <Typography variant="caption">Подобран ИИ</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Tooltip>
  );
};
