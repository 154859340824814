export const BACKEND_URL =
  process.env.REACT_APP_NODE_ENV == 'development'
    ? 'http://localhost:3001/v1'
    : process.env.REACT_APP_CI == 'test'
    ? 'http://51.250.107.24:8001/v1'
    : 'https://meddata.sechenov.ru/v1';

export const url = {
  search: '/search',
  searchResult: '/search/result',
  download: '/download',
  downloadQueue: '/download/queue',
  adminAnalytics: '/admin/analytics',
  users: '/users',
  login: '/',
};
