import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const getExistDatatable = async ({
  userId,
  fileDbId,
  file_name,
  graphql_query,
}) => {
  try {
    const { data } = await axios.post(
      `${BACKEND_URL}/data_loader/get_exist_datatable`,
      {
        userId,
        fileDbId,
        file_name,
        graphql_query,
      },
    );
    return data;
  } catch (error) {
    console.log(error);

    throw error;
  }
};
export const searchResultFileExport = async ({
  graphql_query,
  file_name,
  userId,
}) => {
  try {
    const { data } = await axios.post(`${BACKEND_URL}/data_loader/datatable`, {
      graphql_query,
      file_name,
      userId,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const datatableDeffered = async ({
  graphql_query,
  file_name,
  userId,
  fileDbId,
}) => {
  try {
    const { data } = await axios.post(
      `${BACKEND_URL}/data_loader/datatable/deferred`,
      {
        graphql_query,
        file_name,
        userId,
        fileDbId,
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const datatableDefferedCount = async ({ userId }) => {
  try {
    const { data } = await axios.get(
      `${BACKEND_URL}/data_loader/datatable/deferred/count`,
      {
        params: {
          userId,
        },
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const datatableDefferedList = async ({ last_id, limit, userId }) => {
  try {
    console.log({ last_id, limit, userId });

    const { data } = await axios.get(
      `${BACKEND_URL}/data_loader/datatable/deferred/list`,
      // `https://meddata.sechenov.ru/v1/data_loader/datatable/deferred/list?userId=7d0a5a7d-c059-40eb-a761-bf48a20dcfb0&lastId=0&limit=100`,
      {
        params: {
          userId,
          last_id,
          limit,
        },
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadDatatableDefferedItem = async ({
  download_id,
  userId,
}) => {
  try {
    const { data } = await axios.get(
      `${BACKEND_URL}/data_loader/datatable/deffered/${download_id}`,
      {
        params: {
          userId,
        },
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};
