import { Height } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';

export const AISphere = ({
  AIActive,
  hasResult = { result: false, error: false },
}) => {
  useEffect(() => {
    const sphere = document.getElementById('AIsphere');

    const rows = [];

    for (let i = 0; i < 180; i += 9) {
      rows.push(
        `<div class="AIline" style="transform: rotateY(${i}deg); border-color: ${
          hasResult?.error && 'red'
        }"></div>`,
      );
    }

    sphere.innerHTML = rows.join('');
  }, [hasResult]);

  return (
    <main
      className={`AImain ${AIActive ? 'AIActive' : ''}`}
      style={{ position: 'relative' }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: '100%',
          height: hasResult.result ? '75%' : AIActive ? '76%' : '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        alignItems="center"
        justifyContent="center"
      >
        <img
          alt="Поисковый модуль. Сеченский университет"
          src="/logo.png"
          style={{
            filter: 'contrast(0.5)',
            width: hasResult.result ? '60px' : 'fit-content',
          }}
        />
      </Stack>
      <div class={`AIscene ${hasResult.result ? 'AIHasResult' : ''}`}>
        <div class="AIwrapper">
          <div
            id="AIsphere"
            class={`AIsphere ${hasResult.result ? 'AIHasResult' : ''}`}
          ></div>
        </div>
      </div>
      {AIActive && !hasResult && (
        <TypeAnimation
          speed={200}
          style={{
            whiteSpace: 'pre-line',

            display: 'block',
            fontSize: '1.2rem',
            opacity: 0.7,
            marginTop: '20px',
            lineHeight: 1.5,
          }}
          sequence={[
            `Создаем магию`,
            1000,
            `Проверяем архивы`,
            1000,
            `Генерируем идеи`,
            1000,
            `Создаем будущее`,
            1000,
            `Читаем медкарты`,
            2000,
          ]}
          repeat={Infinity}
        />
      )}
      {hasResult?.result && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>
            {hasResult?.error
              ? 'Не смогли собрать фильтр ('
              : 'Собрали для вас фильтр!'}
          </Typography>
          <Typography>
            {hasResult?.error
              ? 'Попробуйте изменить запрос на более точный'
              : 'Вы можете изменить на вкладке "Выбранные фильтры"'}
          </Typography>
        </Box>
      )}
    </main>
  );
};
