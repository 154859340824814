import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { url } from '../../utils/url';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import {
  DeleteDatatableDeffered,
  downloadDatatableDefferedItem,
} from '../../api/fileExportApi';
import { setAlert } from '../../store/alert.store';
import { useDispatch } from 'react-redux';
import { downloadURI } from '../../utils/downloadURI';
import { CreateEvent } from '../../api/adminAnalyticsApi';
import { getUserRole } from '../../utils/getUserRole';
import { convertStatus } from '../../utils/downloadQueueStatusMap';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ActionApprove } from '../Modal/ActionApprove';
import { Instruction } from '../Instruction/Instruction';

export const DownloadQueueTable = ({
  rows = [],
  user,
  loading,
  removeHandler,
}) => {
  const [disabled, setDisabled] = useState(false);

  const [modal, setModal] = useState({
    open: false,
    closeAction: () => null,
    successAction: () => null,
    closeActionButtonText: '',
    successActionButtonText: '',
    title: '',
    successActionButtonColor: 'error',
    children: '',
    successActionButtonVariant: 'outlined',
    modalVariant: 'primary',
    disabledButton: disabled,
    alertMessages: {
      success: '',
      error: '',
    },
    showCloseButton: true,
  });

  useEffect(() => { }, []);

  const [currentDownloading, setCurrentDownloading] = useState(null);
  const navigate = useNavigate();
  const CreateEventApi = useApi(CreateEvent);

  const stringSlicer = (text, length = 150) => {
    if (text?.length > length) {
      return <Tooltip title={text}>{text.slice(0, length) + '...'}</Tooltip>;
    } else {
      return text;
    }
  };

  const dispatch = useDispatch();

  return (
    <>
      {!loading ? (
        !!rows?.length ? (
          <TableContainer component={Paper} sx={{ mb: 5 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ maxWidth: '550px' }}>Название</TableCell>
                  <TableCell sx={{ maxWidth: '250px' }}>Статус</TableCell>

                  <TableCell>Действие</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <>
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        sx={{ maxWidth: '550px', cursor: 'pointer' }}
                        component="th"
                        scope="row"
                      >
                        {stringSlicer(row.file_name)}
                      </TableCell>
                      <TableCell sx={{ maxWidth: '250px' }}>

                        {convertStatus(row.status)}
                      </TableCell>

                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Button
                            startIcon={<DownloadOutlinedIcon />}
                            aria-label="edit"
                            color="primary"
                            disabled={row?.status != 'finished'}
                            size="small"
                            onClick={() => {
                              CreateEventApi.sendRequest({
                                userId: user.sub,
                                userName:
                                  user.family_name &&
                                    user.given_name &&
                                    user.middle_name
                                    ? user.family_name +
                                    ' ' +
                                    user.given_name +
                                    ' ' +
                                    user.middle_name
                                    : user.email,
                                role: getUserRole(user.role),
                                text_event:
                                  'Скачивание файла - ' + row?.file_name,
                                enum_event: 'DownloadResultWork',
                              });
                              downloadURI(row.url, row?.file_name);

                              dispatch(
                                setAlert({
                                  text: 'Файл успешно скачен',
                                  status: 200,
                                }),
                              );
                            }}
                          >
                            Скачать файл
                          </Button>
                          <IconButton
                            aria-label="delete"
                            color="error"
                            size="small"
                            onClick={() => {
                              setDisabled(false);
                              setModal({
                                closeAction: () =>
                                  setModal({ ...modal, open: false }),
                                successAction: () => {
                                  // save template rest api
                                  removeHandler(row.id);
                                  setModal({ ...modal, open: false });
                                },
                                showCloseButton: true,
                                modalVariant: 'standart',
                                open: true,
                                children: (
                                  <Alert color="warning">
                                    Задача на формирования csv файла с выгрузкой
                                    будет полностью удалена из очереди
                                  </Alert>
                                ),
                                alertMessages: {
                                  success: 'Задача успешно удалена',
                                  error:
                                    'Ошибка при удалении задачи из очереди',
                                },
                                successActionButtonText: 'Удалить',
                                closeActionButtonText: 'Отмена',
                                successActionButtonColor: 'secondary',
                                successActionButtonVariant: 'contained',
                                title:
                                  'Удаление задачи из очереди - ' +
                                  row.file_name,
                              });
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            sx={{
              textAlign: 'center',
              width: '550px',
              display: 'block',
              mx: 'auto',
              py: 4,
            }}
          >
            Вы еще не создали задачу на скачивание шаблона поиска. Перейдите во
            вкладку "Список шаблонов" и скачайте нужный файл{' '}
            <span
              style={{ color: '#3e4cad', cursor: 'pointer' }}
              onClick={() => {
                navigate(url.download);
              }}
            >
              Перейти
            </span>
          </Typography>
        )
      ) : (
        <CircularProgress />
      )}

      <ActionApprove
        open={modal.open}
        successAction={modal.successAction}
        closeAction={modal.closeAction}
        successActionButtonText={modal.successActionButtonText}
        closeActionButtonText={modal.closeActionButtonText}
        successActionButtonColor={modal.successActionButtonColor}
        title={modal.title}
        successActionButtonVariant={modal.successActionButtonVariant}
        modalVariant={modal.modalVariant}
        children={modal.children}
        showCloseButton={modal.showCloseButton}
        alertMessages={modal.alertMessages}
        disabledButton={disabled}
      />
    </>
  );
};
