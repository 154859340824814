// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter_Filter__SmallFilter__Footer__3wcf- {
  width: 100%;
}

.Filter_BigFilter__ToGeZ {
  justify-content: center;
}
.Filter_BigFilter__Box__gj51s {
  width: 98%;
  border-radius: 10px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.9);
  margin: 1% auto;
  height: auto;
  background: #fff;
  z-index: 999;
  top: 0;
  left: 1%;
  border: none;
  outline: none;
  position: absolute !important;
  min-height: 100vh;
}
.Filter_BigFilter__Box__Content__yM83q {
  padding: 20px;
}
.Filter_BigFilter__SearchInFilterResult__TTiqt {
  position: absolute;
  width: 100%;
  z-index: 2;
  margin-top: 56px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  z-index: 9999;
}
.Filter_BigFilter__SearchInFilterResult__TTiqt p {
  padding: 7px 20px;
  display: block;
  margin: 0;
  cursor: pointer;
  transition: 0.1s;
}
.Filter_BigFilter__SearchInFilterResult__TTiqt p:hover {
  transition: 0.1s;
  background: #f1f1f1;
}`, "",{"version":3,"sources":["webpack://./src/components/FilterV2/Filter.module.scss"],"names":[],"mappings":"AAEQ;EACI,WAAA;AADZ;;AAKA;EACI,uBAAA;AAFJ;AAGI;EACI,UAAA;EACA,mBAAA;EACA,uCAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,MAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,iBAAA;AADR;AAGQ;EACI,aAAA;AADZ;AAII;EACI,kBAAA;EACA,WAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,uCAAA;EACA,4BAAA;EACA,aAAA;AAFR;AAGQ;EACI,iBAAA;EACA,cAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;AADZ;AAEY;EACI,gBAAA;EACA,mBAAA;AAAhB","sourcesContent":[".Filter {\n    &__SmallFilter{\n        &__Footer {\n            width: 100%;\n        }\n    }\n}\n.BigFilter {\n    justify-content: center;\n    &__Box {\n        width: 98%;\n        border-radius: 10px;\n        box-shadow: 0 0 50px rgba(0, 0, 0, 0.9);\n        margin: 1% auto;\n        height: auto;\n        background: #fff;\n        z-index: 999;\n        top: 0;\n        left: 1%;\n        border: none;\n        outline: none;\n        position: absolute !important;\n        min-height: 100vh; \n       \n        &__Content {\n            padding: 20px;\n        }\n    }\n    &__SearchInFilterResult {\n        position: absolute;\n        width: 100%;\n        z-index: 2;\n        margin-top: 56px;\n        overflow: hidden;\n        background: #fff;\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n        border-radius: 0 0 10px 10px;\n        z-index: 9999;\n        p {\n            padding: 7px 20px;\n            display: block;\n            margin: 0;\n            cursor: pointer;\n            transition: 0.1s;\n            &:hover {\n                transition: 0.1s;\n                background: #f1f1f1;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Filter__SmallFilter__Footer": `Filter_Filter__SmallFilter__Footer__3wcf-`,
	"BigFilter": `Filter_BigFilter__ToGeZ`,
	"BigFilter__Box": `Filter_BigFilter__Box__gj51s`,
	"BigFilter__Box__Content": `Filter_BigFilter__Box__Content__yM83q`,
	"BigFilter__SearchInFilterResult": `Filter_BigFilter__SearchInFilterResult__TTiqt`
};
export default ___CSS_LOADER_EXPORT___;
