import { createSlice } from '@reduxjs/toolkit';

export const instructionSlice = createSlice({
    name: 'instruction',
    initialState: {
        active: false,
        step: 0,
        count: 0
    },
    reducers: {
        setInstructionActive: (state, action) => {
            return { ...state, active: action?.payload?.active };
        },
        setInstructionStep: (state, action) => {
            return { ...state, step: action?.payload?.step };
        },
        setInstructionCount: (state, action) => {
            return { ...state, count: action?.payload?.count };
        }
    },
});
export const { setInstructionActive, setInstructionStep, setInstructionCount } = instructionSlice.actions;
export default instructionSlice.reducer;
