import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const getCustomUserRole = async (userId) => {
  try {
    const { data } = await axios.get(`${BACKEND_URL}/auth/role/${userId}`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
