import { Box, Button, Card, Divider, CardContent, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Instruction.module.scss'
import { setInstructionStep, setInstructionCount, setInstructionActive } from '../../store/instruction.store'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


export const Instruction = ({ children, title, description, step, position = 'left', nextOtherAction = () => { }, backOtherAction = () => { }, sx = {}, last = false }) => {
    const instruction = useSelector((state) => state.instruction)
    const dispatch = useDispatch()

    return (
        <Box sx={{ position: 'relative', ...sx }}>

            {step == instruction.step && instruction.active && <>
                <Box sx={{ top: '70px', ...(position == 'left' ? { left: 0 } : { right: 0 }), position: 'absolute', zIndex: 999, maxWidth: '400px', minWidth: '300px' }}>
                    <Box className={styles.pulsatingCircle} sx={{ ...(position == 'left' ? { left: '50%' } : { right: '0px', }), }}></Box>
                    <Card variant='outlined' color="primary" sx={{ borderRadius: '8px', background: '#303E9F', }}>

                        <CardContent>
                            <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
                                <Box>
                                    <Typography sx={{ fontWeight: 'bold', color: '#fff' }}>{instruction?.step + 1}. {title}</Typography>
                                </Box>
                                <Box>
                                    <IconButton sx={{ color: '#fff' }} size='small' onClick={() => { dispatch(setInstructionActive({ active: false })); dispatch(setInstructionStep({ step: 0 })) }}><CloseOutlinedIcon /></IconButton>
                                </Box>
                            </Stack>
                            <Divider sx={{ my: 2, background: 'rgba(255,255,255, 0.3)' }} />
                            <Typography sx={{ color: '#fff', lineHeight: 1.3 }}>{description}</Typography>
                            {last ? <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
                                <Button color="inherit" variant="contained" onClick={() => { dispatch(setInstructionActive({ ...instruction, active: false })); nextOtherAction() }}>Завершить</Button>
                                <Button color="inherit" variant="text" sx={{ color: '#fff' }} onClick={() => { dispatch(setInstructionStep({ ...instruction, step: instruction.step - 1 })); backOtherAction() }}>Назад</Button>
                            </Stack> : <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
                                <Button color="inherit" variant="contained" onClick={() => { dispatch(setInstructionStep({ ...instruction, step: instruction.step + 1 })); nextOtherAction() }}>Далее</Button>
                                <Button color="inherit" variant="text" sx={{ color: '#fff' }} onClick={() => { dispatch(setInstructionStep({ ...instruction, step: instruction.step - 1 })); backOtherAction() }}>Назад</Button>
                            </Stack>}

                        </CardContent>
                    </Card>
                </Box>
            </>}
            {children}
        </Box>
    )
}
