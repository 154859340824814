import axios from 'axios';
import { BACKEND_URL } from '../utils/url';
export const AIApi = async (context) => {
  try {
    const { data } = await axios.post(
      `http://51.250.107.24:8001/v1/meddoc/get_filters`,
      {
        context,
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};
