// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Instruction_pulsatingCircle__3bnUE {
  position: absolute;
  top: -30px;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  z-index: 99;
}
.Instruction_pulsatingCircle__3bnUE:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #01a4e9;
  animation: Instruction_pulse-ring__pYZS1 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.Instruction_pulsatingCircle__3bnUE:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #303E9F;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: Instruction_pulse-dot__soNkT 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes Instruction_pulse-ring__pYZS1 {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@keyframes Instruction_pulse-dot__soNkT {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Instruction/Instruction.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,4CAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACE,WAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,2FAAA;AACN;AAEI;EACE,WAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,sCAAA;EACA,oGAAA;AAAN;;AAIE;EACE;IACE,sBAAA;EADJ;EAGE;IACE,UAAA;EADJ;AACF;AAIE;EACE;IACE,qBAAA;EAFJ;EAIE;IACE,mBAAA;EAFJ;EAIE;IACE,qBAAA;EAFJ;AACF","sourcesContent":[".pulsatingCircle {\n    position: absolute;\n    top: -30px;\n    transform: translateX(-50%) translateY(-50%);\n    width: 30px;\n    height: 30px;\n    z-index: 99;\n    \n    &:before {\n      content: '';\n      position: relative;\n      display: block;\n      width: 300%;\n      height: 300%;\n      box-sizing: border-box;\n      margin-left: -100%;\n      margin-top: -100%;\n      border-radius: 45px;\n      background-color: #01a4e9;\n      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;\n    }\n    \n    &:after {\n      content: '';\n      position: absolute;\n      left: 0; \n      top: 0;\n      display: block;\n      width: 100%;\n      height: 100%;\n      background-color: #303E9F;\n      border-radius: 15px;\n      box-shadow: 0 0 8px rgba(0,0,0,.3);\n      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;\n    }\n  }\n  \n  @keyframes pulse-ring {\n    0% {\n      transform: scale(.33);\n    }\n    80%, 100% {\n      opacity: 0;\n    }\n  }\n  \n  @keyframes pulse-dot {\n    0% {\n      transform: scale(.8);\n    }\n    50% {\n      transform: scale(1);\n    }\n    100% {\n      transform: scale(.8);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pulsatingCircle": `Instruction_pulsatingCircle__3bnUE`,
	"pulse-ring": `Instruction_pulse-ring__pYZS1`,
	"pulse-dot": `Instruction_pulse-dot__soNkT`
};
export default ___CSS_LOADER_EXPORT___;
