import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { url } from './utils/url';
import { SearchPage } from './pages/searchPage';
import { AnalyticsPage } from './pages/TemplatesPage';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { LoginPage } from './pages/LoginPage';
import { UsersPage } from './pages/UsersPage';
import { AdminAnalyticsPage } from './pages/AdminAnalyticsPage';
import { DownloadQueuePage } from './pages/DownloadQueuePage';
import { FilterPage } from './pages/filterPage';
const theme = createTheme({
  palette: {
    primary: {
      main: '#303E9F',
      light: '#303E9F',
      dark: '#1d276c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#303E9F12',
      light: '#4b5ac612',
      dark: '#29358712',
      contrastText: '#303E9F',
    },
  },
});

const client = new ApolloClient({
  uri: 'https://meddata.sechenov.ru/v1/graphql',
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

loadDevMessages();
loadErrorMessages();
function App() {
  return (
    // <SechenovId>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route exact path={url.login} element={<LoginPage />} />
            <Route exact path={url.search} element={<SearchPage />} />
            <Route exact path={url.searchResult} element={<SearchPage />} />
            <Route exact path={url.download} element={<AnalyticsPage />} />
            <Route
              exact
              path={url.downloadQueue}
              element={<DownloadQueuePage />}
            />
            <Route
              exact
              path={url.adminAnalytics}
              element={<AdminAnalyticsPage />}
            />
            <Route exact path={url.users} element={<UsersPage />} />
            <Route exact path={url.filter} element={<FilterPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
    // </SechenovId>
  );
}

export default App;
