import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout/MainLayout';
import { Box, Button, Grid, Pagination, Stack } from '@mui/material';

import { Navigate } from 'react-router-dom';
import { url } from '../utils/url';
import { useCookies } from 'react-cookie';
import { useApi } from '../hooks/useApi';
import { getMe } from '../api/sechenovIdApi';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import { DownloadQueueTable } from '../components/AnalyticsTable/DownloadQueueTable';
import {
  datatableDefferedCount,
  datatableDefferedList,
} from '../api/fileExportApi';

export const DownloadQueuePage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const datatableDefferedListApi = useApi(datatableDefferedList);
  const datatableDefferedCountApi = useApi(datatableDefferedCount);

  const userApi = useApi(getMe);
  const [user, setUser] = useState({});
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (cookies.token) {
      userApi.sendRequest(cookies.token).then((result) => {
        setUser(result);
        datatableDefferedListApi
          .sendRequest({
            last_id: 0,
            limit: 999,
            userId: result.sub, //'7d0a5a7d-c059-40eb-a761-bf48a20dcfb0',
            // offset: page * 15,
          })
          .then((response) => setRows(response?.downloads));
        datatableDefferedCountApi
          .sendRequest({ userId: result.sub })
          .then(({ count }) => setTotalCount(count));
      });
    }
  }, []);

  if (!cookies.token) {
    return <Navigate to={url.login} replace={true} />;
  }

  return (
    <MainLayout title="Очередь загрузок">
      <Grid container spacing={2} sx={{ mt: 5, pl: 2 }}>
        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <Box>
            <Button variant="outlined" href={url.download}>
              Список шаблонов
            </Button>
          </Box>
          <Box>
            <Button variant="contained" href={url.downloadQueue}>
              Очередь загрузок
            </Button>
          </Box>
        </Stack>
        <DownloadQueueTable
          rows={rows}
          totalCount={totalCount}
          user={user}
          loading={datatableDefferedListApi.loading}
        />
        {/* {JSON.stringify(rows[rows?.length - 1]?.id)}
        {!datatableDefferedListApi.loading && (
          <Pagination
            sx={{ mt: 3 }}
            count={Math.ceil(totalCount / 15) || 1}
            onChange={(event, value) => {
              setPage(value - 1);
              datatableDefferedListApi
                .sendRequest({
                  last_id: rows[rows?.length - 1].id,
                  limit: 9999,
                  userId: user.sub,
                })
                .then((result) => setRows(result));
            }}
            variant="outlined"
            shape="rounded"
          />
        )} */}
      </Grid>
    </MainLayout>
  );
};
