export const BACKEND_URL =
  process.env.REACT_APP_NODE_ENV == 'development'
    ? 'http://localhost:3001/v1'
    : process.env.REACT_APP_CI == 'test'
      ? 'http://158.160.70.98:8001/v1'
      : 'https://meddata.sechenov.ru/v1';

export const url = {
  search: '/search',
  filter: '/filter',
  searchResult: '/search/result',
  download: '/download',
  downloadQueue: '/download/queue',
  adminAnalytics: '/admin/analytics',
  users: '/users',
  login: '/',
};
