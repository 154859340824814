import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import filterDataJson from './filter.json';
import styles from './Filter.module.scss';
import { useCookies } from 'react-cookie';
import { useApi } from '../../hooks/useApi';
import { getMe } from '../../api/sechenovIdApi';
import {
  getAllUserTemplates,
  insertSearchFilterTemplate,
} from '../../api/templatesApi';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { FilterFieldV2 } from './FilterFieldV2';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { AISphere } from '../Filter/AISphere';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { AIApi } from '../../api/AIApi';
import validator from 'validator';
import { url } from '../../utils/url';
import { getUserRole } from '../../utils/getUserRole';
import { CreateEvent } from '../../api/adminAnalyticsApi';
import { ActionApprove } from '../Modal/ActionApprove';
import { SaveSearchTemplateForm } from '../SaveSearchTemplate/SaveSearchTemplateForm';
import { jsonToGraphQlQuery } from '../../utils/jsonToGraphQlQuery';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Instruction } from '../Instruction/Instruction';

export const FilterV2 = () => {
  // Состояние строки поиска по фильтру
  const [searchInFilterString, setSearchInFilterString] = useState('');

  // Показать выпадаюший результат при поиске по фильтру
  const [showSearchResult, setShowSearchResult] = useState('');

  // Данные для выпадющего списка при поиске по фильтру
  const [searchInFilterResult, setSearchInFilterResult] = useState([]);

  // Состояние строки при вводе исключений по id пациента
  const [execludeClientId, setExecludeClientId] = useState('');

  //массив id пациентов для исключения
  const [execludeClientIdArray, setExecludeClientIdArray] = useState([]);

  // Список шаблонов пользовтаеля
  const [templatesList, setTemplatesList] = useState([]);

  // Авторизованный пользователь
  const [user, setUser] = useState(null);

  // Выбранный элемент из выпадающего списка при поиске по фильтру
  const [selectedSearchItem, setSelectedSearchItem] = useState('');

  // Выбранная вкладка в параметрах фильтра
  const [selectedTab, setSelectedTab] = useState(0);

  //Заполненный фильтр
  const [selectFilterData, setSelectedFilterData] = useState([]);

  //Отвечает за открытие модального окна ИИ
  const [openAI, setOpenAI] = useState(false);

  //Отвечает за активность ИИ
  const [AIActive, setAIActive] = useState(false);

  //Сосотояние контекста ИИ
  const [context, setContext] = useState('');
  //Наличие ответа от ИИ
  const [AIHasResult, setAIHasResult] = useState(false);

  // Открытие модального окна при отчистке фильтра
  const [clearFilterModal, setClearFilterModal] = useState(false);

  // Открытие модального окна сохранения шаблона поиска
  const [saveTemplateModal, setSaveTemplateModal] = useState(false);

  // Шаблон для сохранения
  const [template, setTemplate] = useState({});

  // Открытие/закрытие выпадашки поиска
  const [openSearchSelect, setOpenSearchSelect] = useState(false);

  // Кэшируем поля из json файла с полями фильтра
  const filterData = useMemo(() => filterDataJson, []);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const navigate = useNavigate();

  const getAllUserTemplatesApi = useApi(getAllUserTemplates);
  const userApi = useApi(getMe);
  const AIApiApi = useApi(AIApi);
  const CreateEventApi = useApi(CreateEvent);
  const saveTemplateApi = useApi(insertSearchFilterTemplate);

  const [params, setParams] = useSearchParams();


  useEffect(() => {
    if (params.get('f')) {
      let f_data = JSON.parse(params.get('f'));
      f_data = f_data.map(item => {
        const _data = []
          .concat(...filterData.map((item) => item.params))
          .find((param) => param.key == item.key)
        return {
          ...item,
          fieldType: _data.fieldType,
          defaultData: _data.defaultData
        }
      })
      setSelectedFilterData(f_data);
    }
    if (cookies?.token) {
      userApi.sendRequest(cookies.token).then((result) => {
        setUser(result);
        getAllUserTemplatesApi
          .sendRequest({
            filter: {
              userId: result.sub,
            },
            limit: 99999,
            offset: 0,
          })
          .then((response) => setTemplatesList(response?.data || []));
      });
    }
  }, []);
  const SearchInFilterFieldsHandler = (value) => {
    setSearchInFilterString(value);
    setShowSearchResult(true);
    const paramsArrayInArray = filterData.map((f, i) =>
      f.params?.map((p) => ({
        ...p,
        parentIndex: i,
        parentName: f.name,
      })),
    );
    const params = [].concat(...paramsArrayInArray);

    setSearchInFilterResult(
      params?.filter(
        (p) => p?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) != -1,
      ),
    );
  };

  const AIApiHandler = async () => {
    const { filters } = await AIApiApi.sendRequest(context);
    if (filters['Нет данных'] === '' || Object.keys(filters)?.length == 0) {
      setTimeout(() => {
        setAIHasResult({ result: true, error: true });
      }, 3500);
    } else {
      const resultArray = [];

      for (const i in filters) {
        let graphQlQueryString;
        let value;
        let anyValue = false;

        const fieldType = []
          .concat(...filterData.map((item) => item.params))
          .find((param) => param.key == i)?.fieldType;

        if (fieldType == 'DateRange') {
          value = {
            startDate: new Date(filters[i][0]),
            endDate: new Date(filters[i][1]),
          };
          graphQlQueryString = {
            between: [new Date(filters[i][0]), new Date(filters[i][1])],
          };
        } else if (fieldType == 'MultiSelect') {
          value = filters[i];
          graphQlQueryString = {
            in_: value,
          };
        } else if (fieldType == 'NumberRange') {
          value = {
            from: filters[i][0],
            to: filters[i][1],
          };
          graphQlQueryString = {
            between: [filters[i][0], filters[i][1]],
          };
        } else {
          value = filters[i];
          graphQlQueryString = { eq: value };
        }

        if (filters[i].indexOf('anyValue') != -1 || filters[i][0] === '—') {
          value = null;
          anyValue = true;
        }

        resultArray.push({
          anyValue,
          graphQlQueryString,
          graphQlTable: undefined,
          key: i,
          value,
          fromAI: true,
          defaultData: []
            .concat(...filterData.map((item) => item.params))
            .find((item) => item?.key == i)?.defaultData,
          name: []
            .concat(...filterData.map((item) => item.params))
            .find((item) => item?.key == i)?.name,
          fieldType: []
            .concat(...filterData.map((item) => item.params))
            .find((item) => item?.key == i)?.fieldType,
        });
      }

      console.log(resultArray);
      if (resultArray.filter((r) => r.fieldType)?.length) {
        setSelectedFilterData(resultArray.filter((r) => r.fieldType));
        setTimeout(() => {
          setAIHasResult({ result: true, error: false });
        }, 3500);
      } else {
        setTimeout(() => {
          setAIHasResult({ result: true, error: true });
        }, 3500);
      }
    }
  };
  const ChangeFilterDataHandler = (itemData) => {
    const newData = selectFilterData?.some((item) => item.key == itemData.key)
      ? selectFilterData.map((item) => {

        if (item.key == itemData.key) {
          const _data = []
            .concat(...filterData.map((i) => i.params))
            .find((param) => param.key == itemData.key)
          item = {
            ...item,
            graphQlQueryString: itemData?.graphQlQueryString,
            value: itemData?.value,
            anyValue: itemData?.anyValue,
            defaultData: _data.defaultData
          };
        }

        return item;
      })
      : [...selectFilterData, itemData];
    setSelectedFilterData(newData);
  };

  return (
    <div>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: '100%', mt: 2 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Instruction title="Поиск полей" description="Если вы не можете найти нужное поле в фильтре, начните вводить название поля и система сама предложит подходящие варианты" step={3}>
            <Stack sx={{ width: '300px', position: 'relative' }}>
              <TextField
                id="input-with-icon-adornment"
                label="Поиск по фильтру"
                size="medium"
                fullWidth
                value={searchInFilterString}
                onChange={({ target: { value } }) => {
                  SearchInFilterFieldsHandler(value);
                  setOpenSearchSelect(true);
                }}
              />

              {searchInFilterResult?.length - 1 &&
                searchInFilterString &&
                openSearchSelect && (
                  <div className={styles.BigFilter__SearchInFilterResult}>
                    {searchInFilterResult?.map((item) => (
                      <p
                        onClick={() => {
                          setSelectedSearchItem(item?.key);
                          setSelectedTab(item?.parentIndex);
                          setOpenSearchSelect(false);
                        }}
                      >{`${item?.parentName} - ${item?.name}`}</p>
                    ))}
                  </div>
                )}
            </Stack>
          </Instruction>
          <Instruction title="Выбор шаблона" description="Ранее сохраненные шаблоны можно выбрать в выпадающем списке" step={5}>
            <FormControl
              fullWidth
              key={'select-saved-template'}
              sx={{ width: '300px' }}
            >
              <InputLabel id={`simple-name-label-template`}>
                Выберите шаблон
              </InputLabel>
              <Select
                labelId={`simple-name-label-template`}
                id={`simple-name-template`}
                //   disabled={loading}
                //   value={selectedTemplate?.id}
                label="Выберите шаблон"
                onChange={({ target: { value } }) => {
                  const { filter } = templatesList.find((i) => i.id == value);

                  navigate(url.search + '?f=' + JSON.stringify(filter));
                }}
              >
                {templatesList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Instruction>
          <Box>
            <Instruction title="Исключение из поиска" description="Если вы хотите исключить ID клиента из поиска, укажите их тут. Вы можете добавить несколько ID. Введите ID и нажмите Enter" step={2}>
              <FormControl sx={{ m: 1, width: '300px' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Исключить по ID клиента
                </InputLabel>
                <OutlinedInput
                  label="Исключить по ID клиента"
                  id="outlined-adornment-password"
                  type={'text'}
                  value={execludeClientId}
                  onChange={({ target: { value } }) =>
                    setExecludeClientId(+value)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setExecludeClientIdArray([
                            ...execludeClientIdArray,
                            +execludeClientId,
                          ]);
                        }}
                        edge="end"
                      >
                        <AddCircleOutlineOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Instruction>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <Box>
            {/* <Instruction title="AI помощник" description="Вы можете воспользоваться помощью искуственного интелекта, чтобы подобрать нужные поля в фильтре и подставить значения автоматически" nextOtherAction={() => setOpenAI(true)} step={6}>
              <LoadingButton
                color="success"
                fullWidth
                sx={{ flexShrink: 1, textTransform: 'none' }}
                variant="contained"
                size="large"
                onClick={() => {
                  setOpenAI(true);
                }}
                startIcon={<AutoAwesomeOutlinedIcon />}
              >
                AI помощник
              </LoadingButton>
            </Instruction> */}
          </Box>
          <Box>
            <Instruction title="Применить фильтр" description="Примените заполненный фильтр чтобы получить выборку данных" step={6} backOtherAction={() => setOpenAI(false)} nextOtherAction={() => navigate(url.search)} position='right'>
              <Tooltip title="Применить фильтр">
                <Fab
                  size="medium"
                  color="primary"
                  sx={{ mb: 1, textTransform: 'none' }}
                  variant="contained"
                  // loading={loading}
                  disabled={!!!Object.keys(selectFilterData)?.length}
                  onClick={() => {
                    // searchHandler(currentFilterData, execludeClientIdArray);

                    CreateEventApi.sendRequest({
                      userId: user.sub,
                      userName:
                        user.family_name && user.given_name && user.middle_name
                          ? user.family_name +
                          ' ' +
                          user.given_name +
                          ' ' +
                          user.middle_name
                          : user.email,
                      role: getUserRole(user.role),
                      text_event: 'Выполнение поиска по фильтру',
                      enum_event: 'Search',
                    }).then(() => {
                      navigate(
                        url.search +
                        '?f=' +
                        JSON.stringify(selectFilterData) +
                        '&limit=15&offset=0' +
                        '&ex=' +
                        JSON.stringify(execludeClientIdArray),
                      );
                    });
                  }}
                >
                  <SearchOutlinedIcon />
                </Fab>
              </Tooltip>
            </Instruction>
          </Box>

          <Box>
            <Instruction title="Сохранение шаблона" description="Вы можете сохранить выбранные параметры как шаблон поиска и вернуться к работе в любое время с любого устройства" step={4} position='right'>
              <Tooltip title="Сохранить шаблон поиска">
                <Fab
                  color="secondary"
                  size="medium"
                  sx={{ mb: 1, textTransform: 'none' }}
                  disabled={!Object.keys(selectFilterData)?.length}
                  variant="contained"
                  onClick={() => {
                    setSaveTemplateModal(true);
                  }}
                >
                  <SaveOutlinedIcon />
                </Fab>
              </Tooltip>
            </Instruction>
          </Box>

          <Box>
            <Tooltip title="Отчистить фильтр">
              <Fab
                color="inherit"
                sx={{ mb: 1, textTransform: 'none' }}
                variant="text"
                size="medium"
                onClick={() => {
                  setClearFilterModal(true);
                }}
              >
                <ClearOutlinedIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
      {!!execludeClientIdArray?.length && (
        <div style={{ widht: '100%', display: 'block' }}>
          <Typography variant="caption" sx={{ display: 'block', mt: 0 }}>
            Исключенные ID клиента
          </Typography>
          {execludeClientIdArray.map((item, index) => (
            <Chip
              key={index}
              label={item}
              sx={{ mr: 1 }}
              onDelete={() =>
                setExecludeClientIdArray(
                  execludeClientIdArray.filter((it) => it !== item),
                )
              }
            />
          ))}
        </div>
      )}
      <Divider sx={{ mb: 0.5, mt: 2 }} />
      <TabContext value={selectedTab} sx={{ mt: 3 }}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Instruction title="Вкладки поиска" description="Переключайте вкладки, чтобы найти нужные параметры фильтра" step={0}>
            <TabList
              onChange={(event, value) => setSelectedTab(value)}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {!!(selectFilterData || [])?.length && (
                <Tab
                  label="Выбранные фильтры"
                  value={9999}
                  id={`simple-tab-${9999}`}
                  aria-controls={`simple-tabpanel-${9999}`}
                />
              )}
              {filterData?.map((cat, index) => (
                <Tab
                  key={cat?.name + index + 1}
                  label={cat?.name}
                  value={index}
                  id={`simple-tab-${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                />
              ))}
            </TabList>
          </Instruction>
        </Stack>
        <TabPanel value={9999}>
          <Grid container spacing={2}>

            {selectFilterData?.map((param, i) => (
              <Grid item xs={3}>
                <FilterFieldV2
                  param={param}
                  ChangeFilterDataHandler={ChangeFilterDataHandler}
                  selectFilterData={selectFilterData}
                  setSelectedFilterData={setSelectedFilterData}
                  selectedSearchItem={selectedSearchItem}
                />
              </Grid>
            ))}

          </Grid>
        </TabPanel>
        {filterData?.map((cat, index) => (
          <TabPanel value={index}>
            <Instruction title="Заполняйте параметры" description="Заполните нужные параметры в полях фильтра или включите галочку 'Любое знaчение', чтобы получить все результаты по выбранному полю " step={1}>
              <Grid container spacing={2}>

                {cat?.params?.map((param, i) => (
                  <Grid item xs={3}>
                    <FilterFieldV2
                      param={param}
                      ChangeFilterDataHandler={ChangeFilterDataHandler}
                      selectFilterData={selectFilterData}
                      setSelectedFilterData={setSelectedFilterData}
                    />
                  </Grid>
                ))}

              </Grid>
            </Instruction>
          </TabPanel>
        ))}
      </TabContext>

      <Modal
        open={openAI}
        onClose={() => setOpenAI(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '900px',
            height: '90vh',
            background: '#fff',
            borderRadius: '12px',
            p: 4,
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2}>
              <Box>
                <AutoAwesomeOutlinedIcon />
              </Box>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  AI Помощник
                </Typography>
              </Box>
            </Stack>
            <Box>
              <IconButton onClick={() => setOpenAI(false)}>
                <ClearOutlinedIcon />
              </IconButton>
            </Box>
          </Stack>
          <Typography id="modal-modal-description">
            Напишите вашу задачу, а нейронная сеть подберет подходящие параметры
            для фильтра
          </Typography>
          <Divider sx={{ mt: 2 }} />

          <Stack
            direction={AIActive ? 'column' : 'row'}
            spacing={2}
            sx={{ height: AIActive ? 'auto' : '450px' }}
          >
            <Box sx={{ width: AIActive ? '100%' : 400 }}>
              <AISphere AIActive={AIActive} hasResult={AIHasResult} />
              <Alert severity="warning" sx={{ maxWidth: '400px', mx: 'auto' }}>
                Решение находится на стадии тестирования. Результат может быть
                некорректный
              </Alert>
            </Box>

            <Box sx={{ width: '100%' }}>
              {!AIActive && (
                <>
                  <Instruction title="Опишите задачу" description="Опишите вашу задачу в свободной форме, можете указать цель или назвать список полей. Нажмите на кнопку 'Применить' чтобы ИИ начала подбирать поля и данные для фильтра. Результат работы ИИ вы увидите на экране." step={6} backOtherAction={() => setOpenAI(false)} nextOtherAction={() => setOpenAI(false)}>
                    <FormControl sx={{ width: '100%', mt: 2 }} variant="outlined">
                      {/* <InputLabel htmlFor="outlined-adornment-password">
Опишите вашу задачу
</InputLabel> */}
                      <OutlinedInput
                        fullwidth
                        multiline
                        rows={15}
                        id="outlined-adornment-password"
                        placeholder="Например: Хочу найти всех кто болел ковидом в 2020 году"
                        sx={{ pr: 3, border: 'none !important' }}
                        value={context}
                        onChange={({ target: { value } }) => setContext(value)}
                      />
                    </FormControl>
                  </Instruction>

                  <Stack direction="row" spacing={1} sx={{ mt: 2 }}>

                    <Button
                      startIcon={<SearchOutlinedIcon />}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        setAIActive(true);
                        AIApiHandler();
                      }}
                    >
                      Применить
                    </Button>

                    <Button
                      startIcon={<SearchOutlinedIcon />}
                      variant="outlined"
                      color="inherit"
                      sx={{ opacity: 0.5 }}
                      onClick={() => {
                        setAIActive(false);
                        setContext('');
                      }}
                    >
                      Отчистить
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Stack>

          {AIHasResult && (
            <Stack direction="row" spacing={2} justifyContent="center">
              {!AIHasResult?.error && (
                <Box>
                  <Button
                    sx={{ textTransform: 'none' }}
                    color="success"
                    variant="contained"
                    onClick={() => {
                      setSelectedTab(9999);
                      setOpenAI(false);
                    }}
                  >
                    Посмотреть фильтр
                  </Button>
                </Box>
              )}
              <Box>
                <Button
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    setSelectedFilterData([]);
                    setAIActive(false);
                    setAIHasResult(false);
                    setSelectedTab(0);
                  }}
                >
                  Сбросить фильтр
                </Button>
              </Box>
            </Stack>
          )}
        </Box>
      </Modal>

      <ActionApprove
        closeAction={() => setClearFilterModal(false)}
        successAction={() => {
          setSelectedFilterData([]);
          setParams({});
          setClearFilterModal(false);
        }}
        closeActionButtonText="Отмена"
        open={clearFilterModal}
        disabledButton={false}
        alertMessages={{
          success: 'Параметры фильтра были очищены',
          error: '',
        }}
        successActionButtonText="Сбросить"
        title="Подтвердите сброс фильтра"
      >
        <Alert color="warning">Все данные параметров будут удалены</Alert>
      </ActionApprove>

      <ActionApprove
        closeAction={() => setSaveTemplateModal(false)}
        successAction={() => {
          saveTemplateApi
            .sendRequest({
              ...template,
              file_name: template?.name,
              userId: user.sub,
              patientCount: 0,
              filter: selectFilterData,
              graphql_query: `query ${jsonToGraphQlQuery(
                selectFilterData,
                0,
                0,
              )}`,
            })
            .then((result) => {
              CreateEventApi.sendRequest({
                userId: user.sub,
                userName:
                  user.family_name && user.given_name && user.middle_name
                    ? user.family_name +
                    ' ' +
                    user.given_name +
                    ' ' +
                    user.middle_name
                    : user.email,
                role: getUserRole(user.role),
                text_event: 'Сохранение поискового шаблона - ' + template?.name,
                enum_event: 'SaveTemplate',
              });
            });
          setTemplate({});
          setSaveTemplateModal(false);
        }}
        closeActionButtonText=""
        showCloseButton={false}
        modalVariant="primary"
        open={saveTemplateModal}
        disabledButton={
          !(template?.name && template?.description && template?.purpose)
        }
        alertMessages={{
          success: 'Шаблон успешно сохранен',
          error: '',
        }}
        successActionButtonText="Сохранить"
        successActionButtonColor="secondary"
        successActionButtonVariant="contained"
        title="Сохранение шаблона"
      >
        <SaveSearchTemplateForm
          state={template}
          changeState={(data) => {
            setTemplate(data);
          }}
        />
      </ActionApprove>
    </div>
  );
};
