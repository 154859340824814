import React from 'react';
import { MainLayout } from '../components/Layout/MainLayout/MainLayout';

import { Grid } from '@mui/material';

import { Navigate } from 'react-router-dom';
import { url } from '../utils/url';
import { useCookies } from 'react-cookie';

import { FilterV2 } from '../components/FilterV2/FilterV2';

export const FilterPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  if (!cookies.token) {
    return <Navigate to={url.login} replace={true} />;
  }

  return (
    <MainLayout title="Поисковый модуль">
      <Grid container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={12}>
          <FilterV2 />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
