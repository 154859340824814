import axios from 'axios';
import { BACKEND_URL } from '../utils/url';

export const AggStatsEnumIndicators = async () => {
  try {
    const { data } = await axios.get(
      `${BACKEND_URL}/admin/agg_stats/enum_indicators`,
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const AggStatsYears = async () => {
  try {
    const { data } = await axios.get(`${BACKEND_URL}/admin/agg_stats/years`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
export const AggStatsGetAll = async (year) => {
  try {
    const { data } = await axios.get(`${BACKEND_URL}/admin/agg_stats/get_all`, {
      params: {
        year,
      },
    });
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const AggStatsGetTable = async () => {
  try {
    const { data } = await axios.get(
      `${BACKEND_URL}/admin/agg_stats/get_table`,
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const ActionsGetTable = async () => {
  try {
    const { data } = await axios.get(`${BACKEND_URL}/admin/actions/get_table`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
export const EventsGetTable = async () => {
  try {
    const { data } = await axios.post(`${BACKEND_URL}/admin/events/get_table`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const CreateEvent = async (body) => {
  try {
    const { data } = await axios.post(
      `${BACKEND_URL}/admin/events/create`,
      body,
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const ActionsGetAll = async (body) => {
  try {
    const { data } = await axios.post(
      `${BACKEND_URL}/admin/actions/get_all`,
      body,
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
export const EventsGetAll = async (body) => {
  try {
    const { data } = await axios.post(
      `${BACKEND_URL}/admin/events/get_all`,
      body,
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
