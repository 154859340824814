import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout/MainLayout';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Stack,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
} from '@mui/material';
import { AnalyticsExplorerTable } from '../components/AnalyticsTable/AnalyticsExplorerTable';
import { ActionApprove } from '../components/Modal/ActionApprove';
import { ExportForm } from '../components/ExportForm/ExportForm';
import { Navigate, useNavigate } from 'react-router-dom';
import { url } from '../utils/url';
import { useCookies } from 'react-cookie';
import { useApi } from '../hooks/useApi';
import { getMe } from '../api/sechenovIdApi';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {
  getAllUserTemplates,
  removeTemplate,
  updateTemplate,
} from '../api/templatesApi';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { CreateEvent } from '../api/adminAnalyticsApi';
import { getUserRole } from '../utils/getUserRole';
import { LoadingButton } from '@mui/lab';
import { Instruction } from '../components/Instruction/Instruction';

export const AnalyticsPage = () => {
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const getAllUserTemplatesApi = useApi(getAllUserTemplates);
  const removeTemplateApi = useApi(removeTemplate);
  const updateTemplateApi = useApi(updateTemplate);
  const userApi = useApi(getMe);
  const [user, setUser] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [exportData, setExportData] = useState({ title: '', agree: false });
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const CreateEventApi = useApi(CreateEvent);
  const [orderBy, setOrderBy] = useState('creationDate');
  const [order, setOrder] = useState(false);
  const [search, setSearch] = useState('');

  const GetTemplatesHandler = async (userId, s = search) => {
    let sserch = {};
    if (s) {
      sserch = {
        name: s,
      };
    }
    const result = await getAllUserTemplatesApi.sendRequest({
      filter: {
        userId,
        ...sserch,
      },
      orderBy: [`${orderBy} ${order ? 'asc' : 'desc'}`],
      limit: 15,
      offset: page * 15,
    });
    setRows(result);
  };
  useEffect(() => {
    if (cookies.token) {
      userApi.sendRequest(cookies.token).then((result) => {
        setUser(result);
        GetTemplatesHandler(result.sub, '');
      });
    }
  }, [order, orderBy]);
  const [modal, setModal] = useState({
    open: false,
    closeAction: () => null,
    successAction: () => null,
    closeActionButtonText: '',
    successActionButtonText: '',
    title: '',
    successActionButtonColor: 'error',
    children: '',
    successActionButtonVariant: 'outlined',
    modalVariant: 'primary',
    disabledButton: disabled,
    alertMessages: {
      success: '',
      error: '',
    },
    showCloseButton: true,
  });

  if (!cookies.token) {
    return <Navigate to={url.login} replace={true} />;
  }
  const removeHandler = async (id) => {
    await removeTemplateApi.sendRequest(id, user.sub);
    CreateEventApi.sendRequest({
      userId: user.sub,
      userName:
        user.family_name && user.given_name && user.middle_name
          ? user.family_name + ' ' + user.given_name + ' ' + user.middle_name
          : user.email,
      role: getUserRole(user.role),
      text_event: 'Удаление поискового шаблона ID - ' + id,
      enum_event: 'RemoveTemplate',
    });
    await GetTemplatesHandler(user.sub);
  };

  const updateHandler = async (data) => {
    await updateTemplateApi.sendRequest(data);
    CreateEventApi.sendRequest({
      userId: user.sub,
      userName:
        user.family_name && user.given_name && user.middle_name
          ? user.family_name + ' ' + user.given_name + ' ' + user.middle_name
          : user.email,
      role: getUserRole(user.role),
      text_event:
        'Обновлени поискового шаблона - ' +
        data?.name +
        '. Кол-во пациентов - ' +
        data?.patientCount,
      enum_event: 'EditTemplate',
    });
    await GetTemplatesHandler(user.sub);
  };


  return (
    <MainLayout title="Сохраненные шаблоны поиска">
      <Grid container spacing={2} sx={{ mt: 5, pl: 2 }}>
        <Stack direction="row" spacing={2} sx={{ mb: 3, width: '100%' }}>
          <Box>
            <Button variant="contained" href={url.download}>
              Список шаблонов
            </Button>
          </Box>
          <Box>
            <Instruction title="Очередь загрузок" description="Когда вы нажимаете на кнопку 'Скачать' задача на загрузку файла попадает во вкладку 'Очередь загрузок'. Так как файл нужно подготовить к выгрузке, это занимает какое то время" step={12} nextOtherAction={() => navigate(url.downloadQueue)}>
              <Button variant="outlined" href={url.downloadQueue}>
                Очередь загрузок
              </Button>
            </Instruction>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2} sx={{ width: '100%', mb: 2 }}>

          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <Instruction title="Поиск шаблона" description="Вы также можете найти ранее сохраненный шаблон указав его название в поиск" step={11}>
              <InputLabel htmlFor="search-template-field">
                Поиск по шаблонам
              </InputLabel>
              <OutlinedInput
                id="search-template-field"
                label="Поиск по шаблонам"
                fullWidth
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
                endAdornment={
                  <InputAdornment position="end">
                    {search && (
                      <IconButton
                        edge="end"
                        sx={{ mr: 1 }}
                        onClick={() => {
                          setSearch('');
                          GetTemplatesHandler(user.sub, '');
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </Instruction>
          </FormControl>

          <LoadingButton
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => GetTemplatesHandler(user.sub, search)}
            loading={getAllUserTemplatesApi.loading}
            disabled={!search}
            startIcon={<SearchOutlinedIcon />}
          >
            Поиск
          </LoadingButton>
        </Stack>
        <Instruction sx={{ width: '100%' }} title="Список ранее скаченных данных" description="Все ранее сохраненные шаблоны отображаются в этой таблице. Нажмите на строку в таблице чтобы применить шаблон поиска и увидеть результат на экране, вы также можете скачать шаблон поиска в файл или изменить его." step={10} backOtherAction={() => navigate(url.search)}>
          <AnalyticsExplorerTable
            rows={rows.data}
            totalCount={rows.totalCount}
            removeHandler={(id) => removeHandler(id)}
            updateHandler={(data) => updateHandler(data)}
            user={user}
            loading={getAllUserTemplatesApi.loading}
            setOrder={(data) => setOrder(data)}
            setOrderBy={(data) => setOrderBy(data)}
            orderBy={orderBy}
            order={order}
          />
          <ActionApprove
            open={modal.open}
            successAction={modal.successAction}
            closeAction={modal.closeAction}
            successActionButtonText={modal.successActionButtonText}
            closeActionButtonText={modal.closeActionButtonText}
            successActionButtonColor={modal.successActionButtonColor}
            title={modal.title}
            successActionButtonVariant={modal.successActionButtonVariant}
            modalVariant={modal.modalVariant}
            children={
              <ExportForm
                state={exportData}
                changeState={(data) => {
                  setDisabled(!(data?.title && data?.agree));
                  setExportData(data);
                }}
              />
            }
            showCloseButton={modal.showCloseButton}
            alertMessages={modal.alertMessages}
            disabledButton={disabled}
          />
          {!getAllUserTemplatesApi.loading && (
            <Pagination
              sx={{ mt: 3 }}
              page={page + 1}
              count={Math.ceil(rows?.totalCount / 15) || 1}
              onChange={(event, value) => {
                setPage(value - 1);
                getAllUserTemplatesApi
                  .sendRequest({
                    filter: {
                      userId: user.sub,
                    },
                    limit: 15,
                    offset: (value - 1) * 15,
                  })
                  .then((result) => setRows(result));
              }}
              variant="outlined"
              shape="rounded"
            />
          )}
        </Instruction>

      </Grid>
    </MainLayout>
  );
};
