import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Link, useLocation } from 'react-router-dom';
import styles from './Header.module.scss';
import { Avatar, Stack } from '@mui/material';
import { url } from '../../utils/url';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useCookies } from 'react-cookie';
import { useApi } from '../../hooks/useApi';
import { getMe, logout } from '../../api/sechenovIdApi';
import { CreateEvent } from '../../api/adminAnalyticsApi';
import { getUserRole } from '../../utils/getUserRole';

export const Header = () => {
  const [user, setUser] = useState({});
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const logoutApi = useApi(logout);
  const userApi = useApi(getMe);
  const CreateEventApi = useApi(CreateEvent);

  useEffect(() => {
    if (cookies?.token) {
      userApi.sendRequest(cookies.token).then((result) => setUser(result));
    }
  }, []);

  return (
    <Box sx={{ flexGrow: 1, p: 0, pr: 0 }} className={styles.Header}>
      <AppBar position="static">
        <Toolbar sx={{ paddingRight: '0 !important' }}>
          <Link to="/" className={styles.Header__Logo}>
            <img
              alt="Поисковый модуль. Сеченский университет"
              src="/logo.png"
            />
          </Link>
          <Stack
            direction="row"
            spacing={1}
            sx={{ ml: 14 }}
            className={styles.Header__Navigation}
          >
            <Link
              to={url.search}
              className={
                location.pathname == url.search
                  ? styles.Header__Navigation__Active
                  : styles.Header__Navigation__Normal
              }
            >
              Поисковый модуль
            </Link>
            <Link
              to={url.download}
              className={
                location.pathname == url.download
                  ? styles.Header__Navigation__Active
                  : styles.Header__Navigation__Normal
              }
            >
              Шаблоны
            </Link>

            {(user.role == 'SuperUser' || user.role == 'Admin') && (
              <Link
                to={url.adminAnalytics}
                className={
                  location.pathname == url.users
                    ? styles.Header__Navigation__Active
                    : styles.Header__Navigation__Normal
                }
              >
                Статистика
              </Link>
            )}
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{ ml: 'auto' }}
            alignItems="center"
          >
            <Avatar sx={{ background: '#fff', color: '#303E9F' }}>
              <PersonOutlineOutlinedIcon />
            </Avatar>
            <Stack>
              <Typography>
                {user.family_name} {user.given_name} {user.middle_name}
              </Typography>
              <Typography variant="caption">
                {getUserRole(user.role)}
              </Typography>
            </Stack>
          </Stack>
          <Button
            color="inherit"
            sx={{ ml: 5 }}
            className={styles.Header__Logout}
            onClick={async () => {
              removeCookie('token');
              setUser({});

              CreateEventApi.sendRequest({
                userId: user.sub,
                userName:
                  user.family_name && user.given_name && user.middle_name
                    ? user.family_name +
                      ' ' +
                      user.given_name +
                      ' ' +
                      user.middle_name
                    : user.email,
                role: getUserRole(user.role),
                text_event: 'Выход из системы',
                enum_event: 'Logout',
              });
              await logoutApi.sendRequest();
            }}
          >
            <LogoutIcon />
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
