import { configureStore } from '@reduxjs/toolkit';
import alertReducer from './alert.store';
import userReducer from './user.store';
import instructionReducer from './instruction.store';

export default configureStore({
  reducer: {
    alerts: alertReducer,
    user: userReducer,
    instruction: instructionReducer,
  },
});
